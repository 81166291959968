<template>
  <div>
    <b-table
      v-if="relatedFiles.length"
      ref="refRelatedFilesTable"
      class="position-relative event_list shadow-table"
      :items="relatedFiles"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      no-sort-reset
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
    >
      <!-- Column: Role -->
      <template #cell(fileNo)="data">
        <div class="text-nowrap">
          <b-button
            v-if="canViewThisAction('show', 'File')"
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="link"
            class="text-bold align-middle detail-view-id"
            :to="{ name: 'customers-files-show', params: { id: data.item.id } }"
          >
            {{ data.item.fileNo }}
          </b-button>
          <b-button
            v-else
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="link"
            class="text-bold align-middle detail-view-id not-button"
          >
            {{ data.item.fileNo }}
          </b-button>
        </div>
      </template>

      <template #cell(fileType)="data">
        <div class="text-nowrap">
          <span class="">{{ data.item.fileType }}</span>
          <br>
          <span
            v-if="data.item.location"
            class=""
          >
            {{ data.item.location }}
          </span>
        </div>
      </template>

      <template #cell(purchaserName)="data">
        <div
          class="text-nowrap"
        >
          <span class="">{{ data.item.purchaserName }}</span>
          <br>
          <span
            v-if="data.item.purchaserNric"
            class=""
          >
            {{ data.item.purchaserNric[0] + data.item.purchaserNric.slice(1).replace(/.(?=.{4,}$)/g, '*') }}
          </span>
        </div>
      </template>

      <!-- Column: Status -->
      <template #cell(fileStatus)="data">
        <b-badge
          :variant="`light-${resolveFileStatusVariant(data.item.fileStatus)}`"
          class="text-capitalize"
        >
          {{ data.item.fileStatus.toLowerCase() }}
        </b-badge>
      </template>

      <template #cell(bookingDate)="data">
        <div class="text-nowrap">
          <span class="">{{ dateFormat(data.item.bookingDate) }}</span>
        </div>
      </template>

      <template #cell(confirmDate)="data">
        <div class="text-nowrap">
          <span class="">{{ dateFormat(data.item.confirmDate) }}</span>
        </div>
      </template>

      <template #cell(createdBy)="data">
        <div class="text-nowrap">
          <span class="">{{ data.item.createdBy }}</span>
          <br>
          <span class="">{{ dateFormat(data.item.createdDate) }}</span>
        </div>
      </template>

      <!-- Column: Actions -->
      <template
        v-if="canViewThisAction('show', 'File')"
        #cell(actions)="data"
      >
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item
            v-if="canViewThisAction('show', 'File')"
            :to="{ name: 'customers-files-show', params: { id: data.item.id } }"
          >
            <span class="align-middle ml-50">View</span>
          </b-dropdown-item>
          <b-dropdown-item>
            <span class="align-middle ml-50">Email to Customer</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <div
      v-else
      class="empty_block"
    >
      <h4>No related files found.</h4>
    </div>
  </div>
</template>

<script>
import {
  BTable, BButton, VBTooltip, BBadge, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BTable,
    BButton,
    BBadge,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    relatedFiles: {
      type: [Array, null],
      default: () => [],
    },
  },
  data() {
    return {
      user: store.state.auth.userData,
      render: false,
      sortBy: 'id',
      sortDesc: false,
      isSortDirDesc: true,
      tableItems: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      tableColumns: [
        {
          key: 'fileNo',
          label: 'File No.',
          sortable: true,
        },
        {
          key: 'fileType',
          label: 'Product Description',
          sortable: true,
        },
        {
          key: 'purchaserName',
          label: 'customer',
          sortable: true,
        },
        {
          key: 'fileStatus',
          label: 'file status',
          sortable: true,
        },
        {
          key: 'bookingDate',
          label: 'booking date',
          sortable: true,
        },
        {
          key: 'confirmDate',
          label: 'confirm date',
          sortable: true,
        },
        {
          key: 'createdBy',
          label: 'Created By',
          sortable: true,
        },
        { key: 'actions' },
      ],
    }
  },

  methods: {
    canViewThisAction,
    resolveFileStatusVariant(status) {
      if (status === 'OPEN') return 'warning'
      if (status === 'BOOK') return 'info'
      return 'success'
    },
  },
}
</script>
